import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Enterprise } from '../models/admin/Enterprise';
import { DomainsProvider } from "./domains.provider";

@Injectable()
export class URLProvider {
  // public dominio: string = './app/';
  constructor (
    private domain: DomainsProvider
  ) {}

  public dominio: string = this.domain.getMainDomain();
  private gfsys_service_domain: string = this.domain.getGfsysServiceDomain();

  // private dominio: string = 'https://app.gfsys.info/develop/';
  private dominioFES: string = 'http://34.231.39.246/v2/';
  private fes: string = 'http://localhost:3000/'
  private request_from: string = 'gfsys.cloud';
  private application: string = 'gfsys.cloud';
  private api_key: string = 'api_key';

  /* HEADERS */
  public httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'request-from': this.request_from,
      'application': this.application,
      'api-key': this.api_key
    })
  };
  /* END HEADERS */

  /* CURRENCY */
  public currency(): string {
    return this.dominio + 'currency';
  }
  /* END CURRENCY */

  /* COUNTRY */
  public country(): string {
    return this.dominio + 'country';
  }
  /* END COUNTRY */

  /* COUNTRY */
  public professions(): string {
    return this.dominio + 'professions';
  }
  /* END COUNTRY */

  /* PROVINCE */
  public province(): string {
    return this.dominio + 'province';
  }
  /* END PROVINCE */

  /* CITY */
  public city(): string {
    return this.dominio + 'city';
  }
  /* END CITY */

  /* TRANSACTIONTYPE */
  public transactiontype(): string {
    return this.dominio + 'transaction_type';
  }
  /* END TRANSACTIONTYPE */

  /* CREDIT CARD */
  public credit_card() {
    return this.dominio + 'sricreditcards';
  }
  /* END CREDIT CARD */

  /* ENTERPRISE_RECTOR_ORGANISM */
  public enterprise_rector_organism() {
    return this.dominio + 'enterprise_rector_organism';
  }
  /* END ENTERPRISE_RECTOR_ORGANISM */

  /* TAXEPAYER_TYPE */
  public taxepayer_type() {
    return this.dominio + 'taxpayerstype';
  }
  /* END TAXEPAYER_TYPE */

  /* PAYMENTS_TYPE */
  public payments_type() {
    return this.dominio + 'sripaymentstype'
  }
  /* END PAYMENTS_TYPE */

  /* ACCOUNTING_PLANS */
  public accounting_plans() {
    return this.dominio + 'enterprise_accountingplans';
  }

  public accounting_account_default(enterprise: string) {
    return this.dominio + 'parameteraccountingaccount/' + enterprise;
  }

  public enterprise_plan(enterprise: string) {
    return this.dominio + 'enterpriseplan/' + enterprise;
  }
  /* END ACCOUNTING_PLANS */

  /* ENTITY_TYPE */
  public entity_type() {
    return this.dominio + 'entitytype';
  }
  /* END ENTITY_TYPE */

  /* UPLOAD_FILE */
  public upload_file() {
    return this.dominio + 'uploadfile';
  }

  public upload_xml(enterprise: string){
    return this.dominio + 'electronicalxmlprocess/' + enterprise+'/addxmlBuzon';
  }
  public preview_file_dir() {
    return this.dominio + 'public/uploads/';
  }

  public preview_file_dir_logos() {
    return this.dominio + 'public/logos/';
  }

  public preview_file_dire(url) {
    return this.dominio + 'public/' + url + '/';
  }

  public preview_logo_file() {
    return this.dominio + 'public/logos/';
  }


  /* END UPLOAD_FILE */

  /* VOUCHER_DEFAULT_DATE */
  public voucher_default_date() {
    return this.dominio + 'defaultvoucherdate';
  }
  /* END VOUCHER_DEFAULT_DATE */

  /* ENTERPRISE_OTHER_TAXES */
  public enterprise_other_taxes() {
    return this.dominio + 'enterprisetax';
  }
  /* END ENTERPRISE_OTHER_TAXES */

  /* AUTOMATIC_PROCESS */
  public automatic_process() {
    return this.dominio + 'automaticprocess';
  }
  /* END AUTOMATIC_PROCESS */

  /* ENTERPRISE */
  public enterprise() {
    return this.dominio + 'enterprise';
  }
  public user_reports() {
    return this.dominio + 'userautomatic';
  }

  public userManualreports(enterprise: string) {
    return this.dominio + `reporteinvoice/${enterprise}/closurepdfsms`;
  }
  public enterprise_configuration() {
    return this.dominio + 'enterpriseconfiguration';
  }

  public enterprise_representatives() {
    return this.dominio + 'enterpriserepresentatives';
  }

  public enterprise_invoice() {
    return this.dominio + 'enterpriseinvoce';
  }

  public enterprise_stablishment() {
    return this.dominio + 'enterpriseestablishment';
  }

  public enterprise_othertaxes() {
    return this.dominio + 'enterprisetaxes';
  }

  public enterprise_inventory() {
    return this.dominio + 'enterpriseinventory';
  }
  public enterprise_integration() {
    return this.dominio + 'enterpriseintegration';
  }

  public enterprise_plan_gfsys() {
    return this.dominio + 'enterpriseplansgfsys';
  }
  /* END ENTERPRISE */

  /* BRANCH_OFFICE */
  public branch_office (enterprise: string) {
    return this.dominio + 'branchoffices/' + enterprise;
  }

  public branch_office_matriz (enterprise: string) {
    return this.dominio + 'branchoffices/' + enterprise+'/matriz';
  }
  /* END BRANCH_OFFICE */

  /* BRANCH_OFFICE_LOGO */
  public branch_office_logo(enterprise: string) {
    return this.dominio + 'branchlogo/' + enterprise;
  }
  /* END BRANCH_OFFICE_LOGO */

  /* BRANCH_OFFICE_ENTERPRISE */
  public branch_offices_type () {
    return this.dominio + 'branchofficestype' ;
  }

  public branch_offices_enterprise (enterprise: string) {
    return this.dominio + 'branchofficesenterprise/' + enterprise;
  }
  /* END BRANCH_OFFICE_ENTERPRISE */

  /* WHAREHOUSE */
  public warehouse_type() {
    return this.dominio + 'warehousetype';
  }

  public warehouse (enterprise: string) {
    return this.dominio + 'warehouse/' + enterprise;
  }
  public invproduct_warehouse(enterprise: string) {
    return this.dominio + 'invproductwarehouse/' + enterprise;
  }
  public invproduct_warehouse_validate(enterprise: string) {
    return this.dominio + 'invproductwarehouse/validatestock/' + enterprise;
  }


  /* END WHAREHOUSE */

  /* INVENTORY */

  public inventory_unit() {
    return this.dominio + 'invunit';
  }

  public inventoryfilters(enterprise: string) {
    return this.dominio + 'filtrosview/' + enterprise;
  }

  public reportinventorymanagerial(enterprise: string) {
    return this.dominio + 'reportinventorymanagerial/' + enterprise;
  }

  public reportinventorymanagerialPE(enterprise: string) {
    return this.dominio + 'reportinventorymanagerial/' + enterprise+'/puroego';
  }

  public product_category(enterprise: string) {
    return this.dominio + 'invproductcategory/' + enterprise;
  }
  public product_filter(enterprise: string) {
    return this.dominio + 'invproductfilter/' + enterprise;
  }

  public product_category_filter(enterprise: string) {
    return this.dominio + 'invproductcategoryfilter/' + enterprise;
  }
  public product_image_details(enterprise: string) {
    return this.dominio + 'invproductimage/' + enterprise;
  }
  public product_filter_items_details(enterprise: string) {
    return this.dominio + 'invproductfilteritemdetails/' + enterprise;
  }
  public product_filter_item(enterprise: string) {
    return this.dominio + 'invproductfilteritem/' + enterprise;
  }

  public product_type() {
    return this.dominio + 'invproducttype';
  }

  public product(enterprise: string) {
    return this.dominio + 'invproduct/' + enterprise;
  }
  public productsearch(enterprise: string) {
    return this.dominio + 'invproductsearch/' + enterprise;
  }

  public code_structure(enterprise: string) {
    return this.dominio + 'codestructure/' + enterprise;
  }

  public product_piece(enterprise: string) {
    return this.dominio + 'invproductpiece/' + enterprise;
  }

  public product_list_shopping_car(enterprise: string) {
    return this.dominio + 'shopingcarproductlist/' + enterprise;
  }

  public product_taxes_detail(enterprise: string) {
    return this.dominio + 'invproducttaxesdetail/' + enterprise;
  }

  public product_taxes_detail_lot(enterprise: string) {
    return this.dominio + 'invproducttaxesdetail/' + enterprise + '/lot';
  }

  public product_regsan_detail(enterprise: string) {
    return this.dominio + 'invproducttaxesdetail/' + enterprise + '/regsanitario';
  }

  public product_by_warehouse(enterprise: string) {
    return this.dominio + 'invproduct/' + enterprise + '/wharehouse/';
  }

  public physicalinventory(enterprise: string) {
    return this.dominio + 'physicalinventory/' + enterprise;
  }

  public inventoryreport(enterprise: string) {
    return this.dominio + 'inventoryreport/' + enterprise;
  }
  /* END INVENTORY */

  /* CLIENT/PROVIDER */
  public client_provider_type(enterprise: string) {
    return this.dominio + 'clientprovidertype/' + enterprise;
  }

  public client_provider_destinations(enterprise: string) {
    return this.dominio + 'clientproviderdestination/' + enterprise;
  }


  public client_provider(enterprise: string) {
    return this.dominio + 'clientprovider/' + enterprise;
  }
  /* END CLIENT/PROVIDER */

  /* FES */
  public enterprise_fes() {
    return this.fes + 'enterprise';
  }

  public upload_sign_fes() {
    return this.dominio + 'fileFES';
  }

  public additional_default_info(enterprise: string) {
    return this.dominio + 'additionaldefaultinfo/' + enterprise;
  }

  public payment_method(enterprise: string) {
    return this.dominio + 'paymentstype/' + enterprise;
  }
  /* END FES */

  /* ROLE */
  public role(enterprise: string) {
    return this.dominio + 'userrole/' + enterprise;
  }
  /* END ROLE */

  /* USER_ACCOUNT */
  public user_account(enterprise: string) {
    return this.dominio + 'useraccount/' + enterprise;
  }
  /* END USER_ACCOUNT */

  /* USER_ACCOUNT */
  public user_account_branch_offices_detail(enterprise: string) {
    return this.dominio + 'useraccountbranchoffices/' + enterprise;
  }
  /* END USER_ACCOUNT */

  /* USER_MENU */
  public user_menu(enterprise: string) {
    return this.dominio + 'usermenu/' + enterprise;
  }
  /* END USER_MENU */

  /* GARDS */
  public gard() {
    return this.dominio + 'gards';
  }
  /* END GARDS */

   /* USER_MENU */
   public user_role_permision(enterprise: string) {
    return this.dominio + 'userrolepermision/' + enterprise;
  }
  /* END USER_MENU */

  /* EMISSION_POINT */
  public emission_point(enterprise: string) {
    return this.dominio + 'emissionpoint/' + enterprise;
  }
  /* END EMISSION_POINT */

   /* SRI_VOUCHER_EMISSION_TYPE */
   public sri_voucher_emission_type() {
    return this.dominio + 'srivoucheremisiontype';
  }
  /* END SRI_VOUCHER_EMISSION_TYPE */

    /* VOUCHERS_TYPE */
    public vouchers_type() {
      return this.dominio + 'srivoucherstype';
    }
    /* END VOUCHERS_TYPE */


   /* SEQUENTIAL_INVOICE */
   public sequential_invoice(enterprise: string) {
    return this.dominio + 'sequentialinvoice/' + enterprise;
  }
  /* END SEQUENTIAL_INVOICE */

   /* MASIVE_INVOICE */
   public invoice_masive(enterprise: string) {
    return this.dominio + 'invoicemasive/' + enterprise;
  }
  /* END MASIVE_INVOICE */

  /* SEQUENTIAL DOCUMENT */
  public sequential_document(enterprise: string) {
    return this.dominio + 'sequentialdocument/' + enterprise;
  }
  /* END SEQUENTIAL DOCUMENT */

  /* SEQUENTIAL DOCUMENT */
    public sequential_document_catalog(enterprise: string) {
      return this.dominio + 'sequentialdocumentcatalog/' + enterprise;
    }
    /* END SEQUENTIAL DOCUMENT */

  /* FINANCIAL INSTITUCIONS */
  public financial_institutions() {
    return this.dominio + 'financialinstitutions';
  }
  /* END FINANCIAL INSTITUCIONS */

   /* USER */
   public user() {
    return this.dominio + 'user';
  }
  /* END USER */

  /* EMISSIONS POINT USER ACCOUNT */
  public emission_point_user_account(enterprise: string) {
    return this.dominio + 'emissionpointuser/' + enterprise;
  }
  /* END EMISSIONS POINT USER ACCOUNT */

  /* LOGIN */
   public login() {
    return this.dominio + 'login';
  }
  /* END LOGIN */

  /* USER ENTERPRISE ID */
  public user_enterprise_detail() {
    return this.dominio + 'userenterprise';
  }
  /* END USER ENTERPRISE ID */

   /* USER ACCOUNT ROLE  */
   public user_account_role(enterprise: String) {
    return this.dominio + 'useraccountrole/' + enterprise;
  }
  /* END USER ACCOUNT ROLE  */


  /* INVOICE */

  public invoice(enterprise: string) {
    return this.dominio + 'invoicemain/' + enterprise;
  }

  public invoice_pdf(enterprise: string, type:string, identificator: string , sri: any) {
    return this.dominio + 'pdf/'+ type + '/' + enterprise + '/' + identificator + '?sri='+sri;
  }
  public pdf_accouting_voucher(enterprise: string, type:string, identificator: string) {
    return this.dominio + 'accoutingvoucher/'+ enterprise + '/'+ type + '/'  + identificator;
  }

  public invoice_balance(enterprise: string) {
    return this.dominio + 'invoicemain/balance/' + enterprise;
  }
  public invoice_bydocument(enterprise: string) {
    return this.dominio + 'invoicemain/bydocument/' + enterprise;
  }
  public invoice_findbydocument(enterprise: string) {
    return this.dominio + 'invoicemain/findbydocument/' + enterprise;
  }


  public invoice_item(enterprise: string) {
    return this.dominio + 'invoiceitem/' + enterprise;
  }

  public invoice_item_lot(enterprise: string) {
    return this.dominio + 'invoiceitem/' + enterprise + '/lot';
  }

  public invoice_item_tax_base(enterprise: string) {
    return this.dominio + 'invoiceitemtaxbase/' + enterprise;
  }

  public invoice_tax_base(enterprise: string) {
    return this.dominio + 'invoiceitaxbase/' + enterprise;
  }

  public invoice_payment_method(enterprise: string) {
    return this.dominio + 'invoicepayment/' + enterprise;
  }

  public invoice_additional_info(enterprise: string) {
    return this.dominio + 'invoiceinfo/' + enterprise;
  }

  public invoice_shopping_cart(enterprise: string) {
    return this.dominio + 'invoiceshoppingcart/' + enterprise;
  }

  public delivery_note(enterprise: string) {
    return this.dominio + 'invoicedeliverynote/' + enterprise;
  }
  public daily_seat(enterprise: string) {
    return this.dominio + 'dailyaccountanseat/' + enterprise;
  }
  /* END INVOICE */


  /* ODP */
    public odp(enterprise: string) {
      return this.dominio + 'odpmain/' + enterprise;
    }
    // public invoice_pdf(enterprise: string, type:string, identificator: string) {
    //   return this.dominio + 'pdf/'+ type + '/' + enterprise + '/' + identificator;
    // }
    // public invoice_pdf_accouting_voucher(enterprise: string, type:string, identificator: string) {
    //   return this.dominio + 'accoutingvoucher/'+ enterprise + '/'+ type + '/'  + identificator;
    // }

  /* END ODP */

  /* PASSWORD RESET */
  public customer_password_reset() {
    return this.dominio + 'customerpasswordreset';
  }

  public mail_send_recovery() {
    return this.dominio + 'mail/sendrecoverkey';
  }
  /* END PASSWORD RESET */

  /* SRI IVA */
  public iva_percentage() {
    return this.dominio + 'sriivapercentage';
  }

  /* SRI IVA */
  public taxadministration() {
    return this.dominio + 'taxadministration';
  }

  public iva_type() {
    return this.dominio + 'sriivatype';
  }
  /* END SRI IVA */

   /* SRI DECLARATION */
   public sri_declaration() {
    return this.dominio + 'srideclaration';
  }

  /* END SRI DECLARATION */

  /* ELECTRONICAL VOUCHER */

  public electronical_voucher_invoice() {
    return this.dominioFES + 'invoice';
  }

  public electronical_invoice(enterprise: string) {
    return this.dominio + 'electronicalinvoice/' + enterprise;
  }
  /* END ELECTRONICAL VOUCHER */

  // SRI ELECTRONIC TAX
  public sri_electronic_tax() {
    return this.dominio + 'srielectronictax';
  }

  // END SRI ELECTRONIC TAX

  // SRI ELECTRONIC VAT RATE
  public sri_electronic_vat_rate() {
    return this.dominio + 'srielectronicvatrate';
  }
  // END SRI ELECTRONIC VAT RATE

    // SRI RENT TYPE
    public sri_rent_type() {
      return this.dominio + 'srirenttype';
    }
    // END SRI RENT TYPE

  /* END RATE CAT */
  public rate_cat() {
    return this.dominio + 'ratecat';
  }
  /* RATE CAT */

     /* COST_CENTER */
     public cost_center(enterprise: string) {
      return this.dominio + 'costcenter/' + enterprise;
    }

    public pred_cost_center(enterprise: string) {
      return this.dominio + 'costcenterpredetermine/' + enterprise;
    }

    public pred_cost_center_det(enterprise: string) {
      return this.dominio + 'costcenterpredeterminedetails/' + enterprise;
    }
    /* END COST_CENTER */

/* PURCHASE */
     public purchase(enterprise: string) {
      return this.dominio + 'buymain/' + enterprise;
    }
    public purchasetaxbase(enterprise: string) {
      return this.dominio + 'buytaxbase/' + enterprise;
    }
    public buypurchaseorder(enterprise: string) {
      return this.dominio + 'buypurchaseorder/' + enterprise;
    }
    public purchase_pdf(enterprise: string, id: string) {
      return this.dominio + 'pdf/buy/'+ id+'/'+ enterprise ;
    }


/* END PURCHASE */

/* EGRESS CODE */
public egresscode() {
  return this.dominio + 'egresscode';
}
/* END EGRESS CODE */

public accoutingvoucher() {
  return this.dominio + 'accoutingvoucher';
}

/* SUSTENTATION CODE */
public srisustentation() {
  return this.dominio + 'srisustentation';
}
/* END SUSTENTATION CODE */

/* SRIVOUCHERSTIPE  */
public srivoucherstype() {
  return this.dominio + 'srivoucherstype';
}
/* END SRIVOUCHERSTIPE */

/* SRIVOUCHERSTIPE  */
public srirentretantiontax() {
  return this.dominio + 'srirentretantiontax';
}
/* END SRIVOUCHERSTIPE */

/* SRIRETANTION  */
public sriivaretantion() {
  return this.dominio + 'sriivaretantion';
}
/* END SRIRETANTION */

/*BUY RETENTION*/
public buyretentionmain(enterprise: string) {
  return this.dominio + 'buyretentionmain/'+ enterprise;
}

public buyretentiondetail(enterprise: string) {
  return this.dominio + 'buyretentionitem/'+ enterprise;
}

public buyretentionmainpdf(enterprise: string, type:string) {
if(type==='CODIGO'){
  return this.dominio + 'buyretentionmain/'+ enterprise+'/report/pdfcode';
}else{
  return this.dominio + 'buyretentionmain/'+ enterprise+'/report/pdfseq';
}

}



/* END BUY RETENTION */
/*ACCOUNT ASSIGMENT*/
public acc(enterprise: string) {
  return this.dominio + 'acc/'+ enterprise;
}

public accbank(enterprise: string) {
  return this.dominio + 'accbank/'+ enterprise;
}

public accdetails(enterprise: string) {
  return this.dominio + 'accdetails/'+ enterprise;
}

public accalias() {
  return this.dominio + 'accalias';
}

/* END ACCOUNT ASSIGMENT */


  /*  credit note send mail */
  public electronical_credit_note(enterprise: string) {
    return this.dominio + 'electronicalnotecredit/' + enterprise;
  }
  /* END credit note send mail */

  /*  seller */
  public seller(enterprise: string) {
    return this.dominio + 'baseseller/' + enterprise;
  }
  public xml_not_sign(enterprise: string) {
    return this.dominio + 'sriresponse/' + enterprise;
  }
  /* END pdf_invoice */
  /* END seller */

  /*  credit_note */
  public credit_note(enterprise: string) {
    return this.dominio + 'creditnotemain/' + enterprise;
  }

  public credit_note_tip(enterprise: string) {
    return this.dominio + 'creditnoteadditionalinfo/' + enterprise;
  }

  public credit_note_pdf(enterprise: string, type:string, identificator: string) {
    if(type===''){
      return this.dominio + 'pdf/creditnote/'+ identificator + '/' + enterprise;
    }else{
      return this.dominio + 'pdf/creditnotetirilla/'+ identificator + '/' + enterprise;
    }
  }

  public credit_note_pdf_id(enterprise: string, id: string) {
      return this.dominio + 'pdf/creditnoteid/'+ id + '/' + enterprise;
  }

  /* END credit_note */
   /*  credit_note */
   public credit_note_balance(enterprise: string) {
    return this.dominio + 'creditnotemain/balance/' + enterprise;
  }
  /* END credit_note */

  /*  user_branch_offices */
  public user_branch_offices(enterprise: string) {
    return this.dominio + 'useraccountbranchoffices/' + enterprise;
  }
  /* END user_branch_offices */

  /*  acc_credit_note */
  public acc_credit_note(enterprise: string) {
    return this.dominio + 'acccreditnote/' + enterprise;
  }
  /* END acc_credit_note */

  /*  SALES REPORTS */
  public reporteinvoice(enterprise: string) {
    return this.dominio + 'reporteinvoice/' + enterprise;
  }

  public buyRreport(enterprise: string) {
    return this.dominio + 'buyreport/' + enterprise;
  }

  public buyReportDecIVA(enterprise: string) {
    return this.dominio + 'buyreport/' + enterprise+'/codesri';
  }

  public buyRreportPDF(enterprise: string) {
    return this.dominio + 'buyreport/' + enterprise+'/pdf';
  }


  public buyReportPDFCodes(enterprise: string) {
    return this.dominio + 'buyreport/' + enterprise+'/codesripdf';
  }


  /* END SALES REPORTS */
    /*  sricdicountry */
    public sricdicountry() {
      return this.dominio + 'sricdicountry';
    }
    /* END sricdicountry */

/*  gfsys sequential document catalog */
  public sequentialdocument() {
  return this.dominio + 'gfsyssequentialdocumentcatalog';
  }

/* END gfsys sequential document catalog */
  /*  REGISTRO CONTABLE COMPRAS */
  public buyaccountingrecord(enterprise: string) {
    return this.dominio + 'buyaccountingrecord/' + enterprise;
  }
  public buyitemaccountingrecord(enterprise: string) {
    return this.dominio + 'buyitemaccountingrecord/' + enterprise;
  }
  public buytranscc(enterprise: string) {
    return this.dominio + 'buytranscc/' + enterprise;
  }

  public buyinvproduct(enterprise: string) {
    return this.dominio + 'buyinvproduct/' + enterprise;
  }

  public electronicalbuyretention(enterprise: string) {
    return this.dominio + 'electronicalbuyretention/' + enterprise;
  }

  public buyimportationitem(enterprise: string) {
    return this.dominio + 'buyimportationitem/' + enterprise;
  }

  /* END REGISTRO CONTABLE COMPRAS */

  /* PRODUCTION ORDER */

  public productionordermain(enterprise: string) {
    return this.dominio + 'productionordermain/' + enterprise;
  }

  public productionorderitem(enterprise: string) {
    return this.dominio + 'productionorderitem/' + enterprise;
  }

  public productionorderitemtax(enterprise: string) {
    return this.dominio + 'productionorderitemtax/' + enterprise;
  }

  public productionorderitemfilter(enterprise: string) {
    return this.dominio + 'productionorderitemfilter/' + enterprise;
  }

  public productionorderform(enterprise: string) {
    return this.dominio + 'productionorderform/' + enterprise;
  }

  public productionorderformat(enterprise: string) {
    return this.dominio + 'productionorderformat/' + enterprise;
  }

  public productionordermonitoring(enterprise: string) {
    return this.dominio + 'productionordermonitoring/' + enterprise;
  }

  /* END PRODUCTION ORDER */

  /*  INVENTORY */

  public adjustmenttype(enterprise: string) {
    return this.dominio + 'adjustmenttype/' + enterprise;
  }
  public adjustmentmain(enterprise: string) {
    return this.dominio + 'adjustmentmain/' + enterprise;
  }

  public invtransfermain(enterprise: string) {
    return this.dominio + 'invtransfermain/' + enterprise;
  }

  public invlot(enterprise: string) {
    return this.dominio + 'invlot/' + enterprise;
  }

  public invunidconvert(enterprise: string) {
    return this.dominio + 'invlot/' + enterprise+'/conversion/get';
  }

  public deleteconversion(enterprise: string) {
    return this.dominio + 'invlot/' + enterprise+'/deleteconversion';
  }

  public invblockstock(enterprise: string) {
    return this.dominio + 'invblockstock/' + enterprise;
  }

  public kardex(enterprise: string) {
    return this.dominio + 'kardex/' + enterprise;
  }

  public reportinvproduct(enterprise: string) {
    return this.dominio + 'reportinvproduct/' + enterprise;
  }

  public warehousebranchoffice(enterprise: string) {
    return this.dominio + 'warehousebranchoffice/' + enterprise;
  }

  public providerproductcodes(enterprise: string) {
    return this.dominio + 'invproductproviderconfigurated/' + enterprise;
  }

  public documenttypekardex() {
    return this.dominio + 'documenttypekardex';
  }


  /* END INVENTORY */

  /*  PROJECT */

  public project(enterprise: string) {
    return this.dominio + 'project/' + enterprise;
  }

    /*  END PROJECT */

    /* INCOME PAYMENT PROOF */

    public incomepaymentproof(enterprise: string) {
      return this.dominio + 'incomepaymentproofmain/' + enterprise;
    }

    public incomepaymentproofaction(enterprise: string) {
      return this.dominio + 'incomepaymentproofmain/' + enterprise + '/action';
    }

    public incomepaymentproofreports(enterprise: string) {
      return this.dominio + 'reportincome/' + enterprise ;
    }

    /* END INCOME PAYMENT PROOF */
    public pdfpaymentproof(enterprise: string) {
      return this.dominio + 'accoutingvoucher/' + enterprise;
    }

    public pdfComprobante(enterprise: string) {
      return this.dominio + 'accoutingvoucher/' + enterprise;
    }
    /* EGRESS PAYMENT PROOF */

    public egresspaymentproof(enterprise: string) {
      return this.dominio + 'egresspaymentproofmain/' + enterprise;
    }

    public egresspaymentproofreport(enterprise: string) {
      return this.dominio + 'reportegress/' + enterprise;
    }

    public egresspaymentproofaction(enterprise: string) {
      return this.dominio + 'egresspaymentproofmain/' + enterprise + '/action';
    }

    /* END EGRESS PAYMENT PROOF */
    /*  PROJECT */

    public remission_guide(enterprise: string) {
      return this.dominio + 'remissionguidemain/' + enterprise;
    }

    public remission_guide_driver(enterprise: string) {
      return this.dominio + 'gdrdriver/' + enterprise;
    }

    public remission_guide_vehicle(enterprise: string) {
      return this.dominio + 'gdrvehicle/' + enterprise;
    }

    electronicalremissionguide(enterprise: string){
      return this.dominio + 'electronicalremissionguide/'+ enterprise;
    }

    /*  END REMISSION GUIDE */

    public getProductionOrderExcelData(enterprise: string) {
      return this.dominio + 'productionordermain/' + enterprise + '/exceldata';
    }


    public retention_recived(enterprise: string) {
      return this.dominio + 'invoiceretention/' + enterprise ;
    }
    public invoice_retention(enterprise: string) {
      return this.dominio + 'invoiceretentioncart/' + enterprise ;
    }

    /*  ACCOUNTING PERIODS */

    public accountingperiod() {
      return this.dominio + 'accountingperiod';
    }
    public accountingperioddetail() {
      return this.dominio + 'accountingperioddetail';
    }
    public accountingperioddetailenterprise() {
      return this.dominio + 'accountingperioddetailenterprise';
    }
    /*  END ACCOUNTING PERIODS */

    /* STEP 2 REFOUND PURCHASE */
    public buyrefundmain(enterprise: string) {
      return this.dominio + 'buyrefundmain/' + enterprise;
    }

    /* END STEP 2 REFOUND PURCHASE */

   /* BANK CONCILIATION */
   public bankreconciliation(enterprise: string) {
      return this.dominio + 'bankreconciliation/'+ enterprise;
    }
    /* END BANK CONCILIATION */

    /* ACCOUNTING REPORT MAIN */
    public accounting_report_main(enterprise: string) {
      return this.dominio + 'accountingreport/' + enterprise;
    }
    /* END ACCOUNTING REPORT MAIN */

    /* BALANCEREPORT */
    public balance_report(enterprise: string) {
      return this.dominio + 'balancereport/' + enterprise;
    }

    public balance_cost_center(enterprise: string) {
      return this.dominio + 'balancecostcenter/' + enterprise;
    }

    public balance_report_comp(enterprise: string) {
      return this.dominio + 'balancereport/comparative/' + enterprise;
    }
    /* END BALANCEREPORT */

    /* LEDGER REPORT */
    public ledger_report(enterprise: string) {
      return this.dominio + 'ledgerreport/' + enterprise;
    }

    public docs_utils(enterprise: string) {
      return this.dominio + 'docutils/' + enterprise;
    }
    /* END LEDGER REPORT */

    /* REPORTE DE COMPRA CxP */
    public buyreportcxp(enterprise: string) {
      return this.dominio + 'buyreportcxp/' + enterprise;
    }
    /* END REPORTE DE COMPRA CxP */


    /* LEDGER REPORT */
    public outseats_report(enterprise: string) {
      return this.dominio + 'wrongdailyaccountanseat/' + enterprise;
    }
    /* END LEDGER REPORT */



    /* REPORTE DE VENTA CxC */
    public reportcxc(enterprise: string) {
      return this.dominio + 'reportcxc/' + enterprise;
    }

    public reportecxcdetailexcel(enterprise: string) {
      return this.gfsys_service_domain + enterprise;
    }

     /* REPORTE DE VENTA CxC */
     public reportinvoicedetailed(enterprise: string) {
      return this.dominio + 'reporteinvoice/' + enterprise;
    }

    /* END REPORTE DE VENTA CxC */

    /* ATS */
    public ats(enterprise) {
      return this.dominio + 'ats/' + enterprise;
    }
    /* END ATS */

    /* XML LOADER */

    public electronicalxmlprocess(enterprise) {
      return this.dominio + 'electronicalxmlprocess/' + enterprise;
    }

    public electronicalxmlpdf(ak, enterprise) {
      return this.dominio + 'electronicalxmlprocess/'+enterprise+'/pdf/'+ak;
    }
    public dowloadXmlRoute(ak, enterprise) {
      return this.dominio + 'electronicalxmlprocess/'+enterprise+'/xmldowload/'+ak;
    }
    public downloadXmlInvoiceRoute(id, enterprise) {
      return  this.dominio + 'electronicalinvoice/'+enterprise+'/downloadXmlInvoice/'+id;

    }
    public electronicalxmlpdfattach(id) {
      return this.dominio + 'electronicalxmlprocess/pdfadj/'+id;
    }

    public electronicalxmlupdate(ruc) {
      return this.dominio + 'electronicalxmlprocess/' + ruc+'/update';
    }

  public validateXmlProcess(ruc) {
    return this.dominio + 'electronicalxmlprocess/' + ruc + '/validate-xml-process';
    }
    /* END XML LOADER */

    /* CHECKBOOK */
    public checkbook(enterprise: string) {
      return this.dominio + 'checkbook/' + enterprise;
    }
    /* END CHECKBOOK */

    /* CASHREGISTER */
    public cashregister(enterprise: string) {
      return this.dominio + 'cashregister/' + enterprise;
    }

    public cashregisteropening(enterprise: string) {
      return this.dominio + 'cashregister/opening/' + enterprise;
    }

    public cashregisterclosing(enterprise: string) {
      return this.dominio + 'cashregister/closing/' + enterprise;
    }

    public cashregisterapproving(enterprise: string) {
      return this.dominio + 'cashregister/approve/' + enterprise;
    }

    public cashregisterdeposit(enterprise: string) {
      return this.dominio + 'cashregister/deposit/' + enterprise;
    }
    /* END CASHREGISTER */


    /* expiration_report_configuration */
    public expiration_report_configuration(enterprise: string) {
      debugger
      return this.dominio + 'expirationreportconfiguration/' + enterprise;
    }
    /* END expiration_report_configuration */


    /* payment_channel */
    public payment_channel(enterprise: string) {
      return this.dominio + 'paymentchannels/' + enterprise;
    }
    /* END payment_channel */

    /* buyclearance */
    public buyclearance(enterprise: string) {
      return this.dominio + 'buyclearance/' + enterprise;
    }

    public electronicalbuyclearance(enterprise: string) {
      return this.dominio + 'electronicalbuyclearance/' + enterprise;
    }
    /* END buyclearance */

  /* buyreception */
  public buyinvreception(enterprise: string) {
    return this.dominio + 'buyinvreception/' + enterprise;
  }
  public buyinvreceptionpdf(enterprise: string) {
    return this.dominio + 'buyinvreception/pdf/' + enterprise;
  }

  public buyinvreceptionpdf_accouting_voucher(enterprise: string, type:string, identificator: string) {
    return this.dominio + 'accoutingvoucher/'+ enterprise + '/'+ type + '/'  + identificator;
  }


  /* END buyreception */

    /* PROMOTIONS */
    public promotion_types() {
      return this.dominio + 'discounttype';

    }

    public promotion(enterprise: string) {
      return this.dominio + 'promotion/' + enterprise;

    }
    /* END PROMOTIONS */

    /* SRI CREDENTIALS */
    public sricredentials() {
      return this.dominio + 'sricredentials';
    }
    /* END SRI CREDENTIALS */

    /* COMPANY GROUPS */
    public companygroups() {
      return this.dominio + 'companygroups';
    }
    /* END COMPANY GROUPS */

    /* SHARED MODULES */
    public sharedmodules() {
      return this.dominio + 'sharedmodules';
    }
    /* END SHARED MODULES */

    /* ACCOUNT STATUS REPORT */
    public reportaccountstatus(enterprise) {
      return this.dominio + 'reportaccountstatus/'+ enterprise;
    }
    /* END ACCOUNT STATUS REPORT */

    /* INV PRODUCT CONVERSION */
    public invproductconversion(enterprise) {
      return this.dominio + 'invproductconversion/'+ enterprise;
    }
    /* END INV PRODUCT CONVERSION */

  /* ENTERPRISE AUTOMATIC SRI AUTH */
  public enterpriseautomaticsriauth() {
    return this.dominio + 'enterpriseautomaticsriauth';
  }
  /* END ENTERPRISE AUTOMATIC SRI AUTH */

  /* REPAIRS FORM */
  public repairsform(enterprise) {
    return this.dominio + 'repairsform/'+ enterprise;
  }
  /* END REPAIRS FORM */

  /* MERCHANDISE RECEPTION */
  public merchandisereception(enterprise) {
    return this.dominio + 'merchandisereception/'+ enterprise;
  }
  /* END MERCHANDISE RECEPTION */

   /* SUPPLIES FORMAT */
   public suppliesformat(enterprise) {
    return this.dominio + 'suppliesformat/'+ enterprise;
  }
  /* END SUPPLIES FORMAT */

  /* INV SUPPLIES */
  public invsupplies(enterprise) {
    return this.dominio + 'invsupplies/'+ enterprise;
  }
  /* END INV SUPPLIES */

  public gdr_motive() {
    return this.dominio + 'srigdrmotive';
  }

  /* CASH MANAGEMENT */
  public cashmanagement(enterprise) {
    return this.dominio + 'cashmanagement/'+ enterprise;
  }
  /* END CASH MANAGEMENT */

  /* ALOHA ENTERPRISE */
  public alohaenterprise() {
    return this.dominio + 'gfsysaloha';
  }
  /* END ALOHA ENTERPRISE */

   /* ALOHA ENTERPRISE */
   public gfsysalohaclient() {
    return this.dominio + 'gfsysalohaclient';
  }
  /* END ALOHA ENTERPRISE */


  /* INTERNALLY PRODUCT MADE */
  public invinternallyproductmade(enterprise) {
    return this.dominio + 'invinternallyproductmade/'+ enterprise;
  }
  /* END INTERNALLY PRODUCT MADE */

  /* PRODUCT TEMPLATE */
  public invproducttemplate(enterprise) {
    return this.dominio + 'invproducttemplate/'+ enterprise;
  }
  /* END PRODUCT TEMPLATE */

  /* SLIDER REGISTER */
  public sliderregister() {
    return this.dominio + 'sliderregister';
  }
  /* END SLIDER REGISTER */

  /* TAX PAYERS TYPE CONTENT */
  public taxpayertypescontent() {
    return this.dominio + 'taxpayertypescontent';
  }
  /* END TAX PAYERS TYPE CONTENT */

   /* TAX REGIME */
   public taxregime() {
    return this.dominio + 'taxregime';
  }
  /* END TAX REGIME */

  /* GRID PERMISSIONS */
  public gridpermissions(enterprise) {
    return this.dominio + 'gridpermissions/' + enterprise;
  }
  /* END GRID PERMISSIONS */

   /* FAVORITE MENU */
   public favoritemenu(enterprise) {
    return this.dominio + 'favoritemenu/' + enterprise;
  }
  /* END FAVORITE MENU */

  /* USER WEBSOCKET CONNECTION */
  public userswebsockets() {
    return this.dominio + 'userwebsocketconnections';
  }
  /* END USER WEBSOCKET CONNECTION */

  /* NOTIFICATIONS */
  public notifications(enterprise) {
    return this.dominio + 'notifications/' + enterprise;
  }
  /* END NOTIFICATIONS */

    /* INTEGRATIONS */
  public integration() {
      return this.dominio + 'integrations';
    }
    /* END INTEGRATIONS */

  /* PIECERESERVATIONRULE */
  public piecereservationrule(enterprise) {
    return this.dominio + 'piecereservationrule/' + enterprise;
  }
  /* END PIECERESERVATIONRULE */

  /* COLLECTION MANAGEMENT */
  public collectionmanagement(enterprise) {
    return this.dominio + 'collectionmanagement/' + enterprise;
  }

  public userscollectionmanagement(enterprise) {
    return this.dominio + 'collectionmanagement/' + enterprise+'/users';
  }
  /* END COLLECTION MANAGEMENT */

   /*  changeapp */
   public changeapp(enterprise: string) {
    return this.dominio + 'changeapp/' + enterprise;
  }
  /* END changeapp */

   /*  integrationlogs */
   public integrationlogs(enterprise: string) {
    return this.dominio + 'integrationlogs/' + enterprise;
  }
  /* END integrationlogs */

  /*  medicalconsultation */
  public medicalconsultation(enterprise: string) {
    return this.dominio + 'medicalconsultation/' + enterprise;
  }
  /* END medicalconsultation */


  /*  ageinventory */
  public ageinventory(enterprise: string) {
    return this.dominio + 'ageinventory/' + enterprise;
  }
  /* END ageinventory */

    /*  ageinventory */
    public pricelist(enterprise: string) {
      return this.dominio + 'pricelist/' + enterprise;
    }
    /* END pricelist */

  /*  ageinventory */
  public buyrequisition(enterprise: string) {
    return this.dominio + 'buyrequisition/' + enterprise;
  }
  /* END pricelist */

    /*  kardexreprocessaudit */
    public kardexreprocessaudit(enterprise: string) {
      return this.dominio + 'kardexreprocessaudit/' + enterprise;
    }
  /* END kardexreprocessaudit */

  public getxmlNotSaved() {
    return this.dominio + 'xmlNotSaved/get';
  }
  /* Integracion con AJEGROUP Middleware */
  public integrationAjeGroup() {
    return this.dominio + 'integrationaje/';
  }
}

