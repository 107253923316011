import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Invoice } from '../../models/client/Invoice';
import { InvoiceItem } from '../../models/client/InvoiceItem';
import { InvoiceTaxBase } from '../../models/client/InvoiceTaxBase';
import { InvoicePaymentMethod } from '../../models/client/InvoicePaymentMethod';
import { InvoiceAdditionalInfo } from '../../models/client/InvoiceAdditionalInfo';
import { URLProvider } from '../url.providers';
import { AdditionalDefaultInfo } from '../../models/client/AdditionalDefaultInfo';
import { ViewPaginator } from '../../models/client/ViewPaginator';

@Injectable()
export class OdpProvider {

  constructor(
    private http: HttpClient,
    private urlProvider: URLProvider
  ) {}

  public allOdp(enterprise: string, token: string = '', value: string = '') {
    if (token !== '') {
      return this.http.get<Invoice[]>(this.urlProvider.odp(enterprise) + '?' + token + '=' + value, this.urlProvider.httpOptions);
    } else {
      return this.http.get<Invoice[]>(this.urlProvider.odp(enterprise), this.urlProvider.httpOptions);
    }
  }

  public allOdp3Tokens(enterprise: string, token: string = '', value: string = '',  token2: string = '', value2: string = '', token3: string, value3: string = '') {
    if (token !== '') {
      return this.http.get<Invoice[]>(this.urlProvider.odp(enterprise) + '?' + token + '=' + value + '&' + token2 + '=' + value2+ '&' + token3 + '=' + value3, this.urlProvider.httpOptions);
    } else {
      return this.http.get<Invoice[]>(this.urlProvider.odp(enterprise), this.urlProvider.httpOptions);
    }
  }

  public getListOdp(enterprise: string, start_date, end_date: string = '', branch_offices: string= '', row = 10) {
    return this.http.get<ViewPaginator>(this.urlProvider.odp(enterprise) + '/list' + '?' + 'start_date' + '=' + start_date + '&' + 'end_date' + '=' + end_date
    +'&branch_offices_id='+ branch_offices+ '&row='+ row, this.urlProvider.httpOptions);
  }

  public getViewOdp(enterprise: string, filter: string = '', branch_offices: string= '', row = 10) {
    return this.http.get<ViewPaginator>(this.urlProvider.odp(enterprise) + '/view?' + `${filter}` + '&row='+row, this.urlProvider.httpOptions);
  }

  public getOdp(enterprise: string, id: string) {
    return this.http.get<any[]>(this.urlProvider.odp(enterprise) + '/' + id, this.urlProvider.httpOptions);
  }
  public getLiquidation(enterprise: string, id: string) {
    return this.http.get<any[]>(this.urlProvider.odp(enterprise) + '/liquidation01/' + id, this.urlProvider.httpOptions);
  }

  public getConsigmentId(enterprise: string, id: string) {
    return this.http.get<any[]>(this.urlProvider.odp(enterprise) + '/consignment/' + id, this.urlProvider.httpOptions);
  }

  public getOdpItemsReport(enterprise: string, data: any) {
    return this.http.get<any[]>(this.urlProvider.odp(enterprise) + '/reo/report' +data,  this.urlProvider.httpOptions);
  }

  public getOdpItems(enterprise: string, data: any) {
    return this.http.post<any[]>(this.urlProvider.odp(enterprise) + '/get-items' , data, this.urlProvider.httpOptions);
  }

  public pdfOdp(enterprise: string, id: string) {
    return this.urlProvider.odp(enterprise) + '/pdf/' + id;
  }

  public pdfCOE(enterprise: string, id: string) {
    return this.urlProvider.odp(enterprise) + '/coepdf/' + id;
  }

  public pdfCOD(enterprise: string, id: string) {
    return this.urlProvider.odp(enterprise) + '/codpdf/' + id;
  }
  public ExcelItemsOdp(enterprise: string, id: string) {
    return this.urlProvider.odp(enterprise) + '/excel-items/' + id;
  }

  public tirillaOdp(enterprise: string, id: string) {
    return this.urlProvider.odp(enterprise) + '/tirilla/' + id;
  }

  public getOdpNextPreviousPage(url, per_page='') {
    return this.http.get<ViewPaginator>(url, this.urlProvider.httpOptions);
  }

  public getNextSequential(enterprise: string, branch_office: string, stablishment, project) {
    return this.http.get<any>(this.urlProvider.odp(enterprise)+ '/sequential?branch_office_code='+branch_office+'&stablishment_code='+stablishment+'&project_name='+ project ,this.urlProvider.httpOptions);
  }

  public getNextSequentialConsignment(enterprise: string, branch_office: string, stablishment, project) {
    return this.http.get<any>(this.urlProvider.odp(enterprise)+ '/sequentialconsignment?branch_office_code='+branch_office+'&stablishment_code='+stablishment+'&project_name='+ project ,this.urlProvider.httpOptions);
  }
  public getNextSequentialLiquidation(enterprise: string, branch_office: string, stablishment, project) {
    return this.http.get<any>(this.urlProvider.odp(enterprise)+ '/sequentialliquidation?branch_office_code='+branch_office+'&stablishment_code='+stablishment+'&project_name='+ project ,this.urlProvider.httpOptions);
  }
  // public getListInvoiceBalance(enterprise: string) {
  //   return this.http.get<any>(this.urlProvider.invoice_balance(enterprise) , this.urlProvider.httpOptions);
  // }

  // public geInvoiceByDocument(enterprise: string, document: string) {
  //   return this.http.get<any>(this.urlProvider.invoice_bydocument(enterprise)+'/' + document , this.urlProvider.httpOptions);
  // }

  // public findByDocument(enterprise: string, document: string) {
  //   return this.http.get<any>(this.urlProvider.invoice_findbydocument(enterprise)+'/' + document , this.urlProvider.httpOptions);
  // }
  // Invoice
  public insertOdp(enterprise: string, invoice: UpdateOdp) {
    return this.http.post<number>(this.urlProvider.odp(enterprise), invoice, this.urlProvider.httpOptions);
  }
  public insertConsginment(enterprise: string, Consignment: any) {
    return this.http.post<number>(this.urlProvider.odp(enterprise)+'/consignment', Consignment, this.urlProvider.httpOptions);
  }

  public getConsigment(enterprise: string ) {
    return this.http.get<any>(this.urlProvider.odp(enterprise) + '/consignments/get', this.urlProvider.httpOptions);
  }

  public getConsigmentItems(enterprise: string,id:any,ids:any,edit:any) {
    return this.http.put<any>(this.urlProvider.odp(enterprise) + '/consignments/getitem/'+id+'?edit='+edit, ids, this.urlProvider.httpOptions);
  }
  public insertLiquidation(enterprise: string, liquidation: any) {
    return this.http.put<Boolean>(this.urlProvider.odp(enterprise)+ '/liquidation/new',liquidation, this.urlProvider.httpOptions);
  }
  public updateLiquidation(enterprise: string, liquidation: any) {
    return this.http.put<Boolean>(this.urlProvider.odp(enterprise)+ '/liquidation/update',liquidation, this.urlProvider.httpOptions);
  }

  public coeValidation(enterprise: string, products: any) {
    return this.http.put<Boolean>(this.urlProvider.odp(enterprise)+ '/liquidation/duplicate',products, this.urlProvider.httpOptions);
  }
  public deleteLiquidation(enterprise: string, id: any) {
    return this.http.delete<Boolean>(this.urlProvider.odp(enterprise)+ '/liquidation/delete/'+id, this.urlProvider.httpOptions);
  }
  public getLiquidations(enterprise: string) {
    return this.http.get<Boolean>(this.urlProvider.odp(enterprise)+ '/liquidation/list', this.urlProvider.httpOptions);
  }
  public updateOdp(enterprise: string, invoice: Invoice, id: string) {
    return this.http.put<Boolean>(this.urlProvider.odp(enterprise) + '/' + id, invoice, this.urlProvider.httpOptions);
  }

  public editOdp(enterprise: string, invoice: any) {
    return this.http.put<Boolean>(this.urlProvider.odp(enterprise), invoice, this.urlProvider.httpOptions);
  }

  public editConsigment(enterprise: string, invoice: any) {
    return this.http.put<Boolean>(this.urlProvider.odp(enterprise)+ '/consignment/edit', invoice, this.urlProvider.httpOptions);
  }

  public sendAceptTransferCOE(enterprise: string, id: any) {
    return this.http.put<Boolean>(this.urlProvider.odp(enterprise)+ '/sendcoe/'+id,null, this.urlProvider.httpOptions);
  }

  public backAceptTransferCOD(enterprise: string, id: any) {
    return this.http.put<Boolean>(this.urlProvider.odp(enterprise)+ '/backcod/'+id,null, this.urlProvider.httpOptions);
  }

  public deleteOdp(enterprise: string, id: string, updated_by: any) {
    return this.http.put<Boolean>(this.urlProvider.odp(enterprise) + '/delete/' + id , updated_by, this.urlProvider.httpOptions);
  }

  public allOdpByRange(enterprise: string, branch_offices_id: string = '',  start_date: string = '', end_date: string = '') {
    if (branch_offices_id === '0') {
      branch_offices_id='';
    }
    return this.http.get<Invoice[]>(this.urlProvider.odp(enterprise) + '?branch_offices_id=' + branch_offices_id + '&start_date=' + start_date+ '&end_date=' + end_date, this.urlProvider.httpOptions);

  }

  public checkStockBeforeBilling(enterprise: string, id:string ) {
    return this.http.get<any>(this.urlProvider.odp(enterprise) + '/validatestock/' + id, this.urlProvider.httpOptions);

  }

  

}


export interface UpdateOdp {
  Odp01Main: any;
  Odp02Item: any[],
  delete_Odp02Item?: any[],
  Odp04TaxBase: any[],
  Odp05PaymentMethod: any[],
  delete_Odp06AdditionalInfo?: any[],
  Odp06AdditionalInfo: any[]
};
