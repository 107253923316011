import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DxDataGridComponent } from "devextreme-angular";
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';

import { PendingVouchers } from '../../../models/client/IncomePaymentProofMain';
import { IncomePaymentProofMainProvider } from 'src/app/providers/client/income_payment_proof_main.provider';
import { ViewPaginator } from 'src/app/models/client/ViewPaginator';
import draggable from 'devextreme/ui/draggable';

@Component({
  templateUrl: './income-payment-vouchers.modal.html',
  styleUrls: ['./income-payment-vouchers.modal.scss']
})

export class IncomePaymentVouchersModal {
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
  @ViewChild('dataGridInvoices', { static: false }) dataGridInvoices: DxDataGridComponent;
  public header_columns = ["check","client_name",
    "expiration",
    "remaining_balance",
    "register_date",
    "document",
    "total",
    "retention",
    "credits"];
    public filter = {
      date: '',
      document: ''
    }
  constructor(
    public dialogRef: MatDialogRef<IncomePaymentVouchersModal>,
    private incomePaymentProofMainService: IncomePaymentProofMainProvider,
    @Inject(MAT_DIALOG_DATA) public data: {selected: any, pending_vouchers: PendingVouchers[], clients_seleted: any[], date : any, enterprise:string, 
      invoice: boolean, exchange?: boolean}
  ) {
    debugger
    console.log('data modal', this.data);
    for (const item of this.data.pending_vouchers) {
      let data_2 = {}
      for (const key in item) {
        if (Object.prototype.hasOwnProperty.call(item, key)) {
          data_2[key] = item[key];
        }
      } 
      this.pending_vouchers.push(data_2)
      this.selected_vouchers.push(data_2);
    }
    if (this.data.clients_seleted) {
      
      this.getInvoiceByClients();
    }
  }
  public pending_vouchers = []
  public selected_vouchers = []
  public view_paginator: ViewPaginator;
  public select_all:boolean=false;
  sumRemainingBalance(){
    debugger;
    let total = 0;
    if (this.dataGridInvoices && this.dataGridInvoices.selectedRowKeys) {
      const vouchers: PendingVouchers[] = this.dataGridInvoices.selectedRowKeys;
      for (const item of vouchers) {
        if (item.voucher_type == "Invoice") {
          total = total + item.remaining_balance
        } else {
          total = total - item.remaining_balance
        }
      }
    }
    // total = Number((selected.map(t => (t.remaining_balance * 1)).reduce((acc, value) => ((acc + value) * 1), 0)).toFixed(2));
    return (total).toFixed(2);
  }

  getInvoiceByClients() {
    if (this.data.clients_seleted) {
      for (const item of this.data.clients_seleted) {
        item.date = moment(this.data.date).format('YYYY-MM-DD');
      }
      this.data.pending_vouchers = [];
      let filter = 'document='+this.filter.document +'&date='+ this.filter.date
      filter = this.data.exchange?filter+'&exchange=true':filter;
      this.incomePaymentProofMainService.getInvoiceByClientsPaginate(this.data.enterprise, this.data.clients_seleted, filter).subscribe(
        (data: {invoice: ViewPaginator, credit_note: ViewPaginator, advances: ViewPaginator}) => {
          debugger;
          if(data.invoice && data.invoice.data.length > 0) {
            this.data.pending_vouchers = this.data.pending_vouchers.concat(data.invoice.data)
          }
          if(data.credit_note && data.credit_note.data.length > 0) {
            this.data.pending_vouchers = this.data.pending_vouchers.concat(data.credit_note.data)

          }
          if(data.advances && data.advances.data.length > 0) {
            this.data.pending_vouchers = this.data.pending_vouchers.concat(data.advances.data)
          }
          this.printInitSelected();

          console.log('pendding vouchers', this.pending_vouchers);
          this.view_paginator = data.invoice;
          this.view_paginator.to = this.view_paginator.to + data.invoice.to + data.advances.to
          this.view_paginator.last_page = this.view_paginator.last_page + data.invoice.last_page + data.advances.last_page
          this.view_paginator.total = this.view_paginator.total + data.invoice.total + data.advances.total
          this.data.selected.forEach(element => {
            let index = this.data.pending_vouchers.findIndex(v=> v.ID == element)
            if (index >= 0) {
              this.data.pending_vouchers[index].check = true;
            }
          });
        }
      )
    }

  }

  public printInitSelected(): void {
    debugger
    if (!this.data.selected) {
      this.data.selected = [];
    }
    if (!this.data.pending_vouchers){
      this.data.pending_vouchers = [];
    }
    this.selected_vouchers.forEach(voucher => {
      const item = this.data.pending_vouchers.find(i => i.ID == voucher.ID);
      const itemSelected = this.dataGridInvoices.selectedRowKeys.find(i => i.ID == voucher.ID);
      if (item && !itemSelected) {
        this.dataGridInvoices.selectedRowKeys.push(item);
      }
    });
  }

  close() {
    debugger;
    this.data.pending_vouchers = this.dataGridInvoices.selectedRowKeys;
    this.data.selected = this.dataGridInvoices.selectedRowKeys.map(e => e.ID);
    this.dialogRef.close(this.data);
  }

}
